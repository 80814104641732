import { Injectable } from "@angular/core";
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import { Router } from "@angular/router";
import { AuthService } from "./auth.service";
import { IToken } from "./jwt-token.interface";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(private router: Router, private authService: AuthService) {}

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (
            request.url.endsWith("auth/sign-in") ||
            request.url.endsWith("auth/ldap")
        ) {
            return next.handle(request);
        }

        const token = this.getToken();
        if (token === null) {
            this.redirectNavigateLogin();
            // return next.handle(request);

        }

        request = request.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`,
            },
        });

        const refreshToken = this.getRefreshToken();
        return next.handle(request).pipe(
            catchError((error) => {
                if (
                    error.status === 401 &&
                    error.error.message.message === "Session timeout"
                ) {
                    if (refreshToken) {
                        return this.authService.refreshToken(refreshToken).pipe(
                            switchMap((res: IToken) => {
                                localStorage.setItem(
                                    "accessToken",
                                    res.accessToken
                                );
                                localStorage.setItem(
                                    "refreshToken",
                                    res.refreshToken
                                );

                                return next.handle(
                                    this.setTokenHeader(
                                        request,
                                        res.accessToken
                                    )
                                );
                            }),
                            catchError((err) => {
                                this.redirectNavigateLogin();
                                return throwError(err);
                            })
                        );
                    }

                    this.redirectNavigateLogin();
                }

                return throwError(error);
            })
        );
    }

    private setTokenHeader(request: HttpRequest<any>, token: string) {
        return request.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`,
            },
        });
    }

    getToken() {
        return localStorage.getItem("accessToken");
    }

    getRefreshToken() {
        return localStorage.getItem("refreshToken");
    }

    redirectNavigateLogin() {
        this.router.navigate(["/login"], {
            state: { shownMessage: true },
        });
    }
}
